@swatches : {
	green          : #00DC7D;
	yellow         : #FFFF4D;
	blue           : #001EE6;
	black          : #000000;
	white          : white;
	textBlack      : #000;
	lightBgGray    : darken( #E5E5E5, 4 );
	lightestBgGray : darken( #F9F9F9, 2 );
}
@spacings : {
	/*
	mini    : 0.1rem;
	small   : 0.25em;
	default : 0.5rem;
	medium  : 1.0rem;
	large   : 2.5rem;
	*/
}
@screenWidths : {
	xs : 350px;
	sm : 750px;
	md : 1023px;
	lg : 1500px;
	xl : 1800px;
}
@mq : {
	xs : ~"(max-width:" @screenWidths[xs] ~")";
	sm : ~"(max-width:" @screenWidths[sm] ~")";
	md : ~"(max-width:" @screenWidths[md] ~")";
	dt : ~"(min-width:" @screenWidths[md] ~")";
	lg : ~"(min-width:" @screenWidths[lg] ~")";
	xl : ~"(min-width:" @screenWidths[xl] ~")";
}
@transitions : {
	colorChangeDuration : 0.1s;
	splashscreenDisappearDuration : 0.5s;
}

/*
@assetsPath: "@/../../assets";
Das ist eine gute Idee allerdings bekomme ich sie nicht
via background-image: url('@{assetsPath}/images/icon--rightArrow.svg');
zum laufen

@paths : {
assets: "@/../../assets";
images: "@/../../assets/images";
}
*/
